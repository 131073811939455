.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 50px; }
  .grid > div .fx-anim {
    background: #fff;
    display: block;
    margin: auto;
    padding-bottom: 100%;
    max-width: 400px; }
    .grid > div .fx-anim iframe {
      width: 100%;
      height: 100%;
      border: 0; }

.fx-anim__animation {
  z-index: 1; }

.fx-anim {
  display: block;
  margin: auto;
  padding-bottom: 100%;
  max-width: 400px; }
  .fx-anim iframe {
    width: 100%;
    height: 100%;
    border: 0; }

.anim1-phone {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%; }

.anim1-notif {
  left: 5%;
  position: absolute;
  z-index: 3;
  top: 30%;
  width: 90%; }

.anim1-notif2 {
  left: 5%;
  position: absolute;
  z-index: 2;
  top: 30%;
  width: 90%; }

.anim2-bg {
  margin-left: -30%;
  margin-right: -30%;
  width: 160%;
  z-index: 1; }

.anim2-box {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 5% 8%;
  position: absolute;
  top: 25%;
  left: 10%;
  z-index: 2;
  background: #fff;
  width: 60%; }

.anim2-title {
  margin-bottom: 2%;
  max-width: 50%; }

.anim2-row {
  display: flex;
  gap: 5%;
  width: 100%;
  align-items: center;
  margin: 4% 0; }

.anim2-text1 {
  height: 10px;
  background: #eee;
  width: 10%; }

.anim2-text2 {
  height: 10px;
  background: #eee;
  width: auto;
  flex-grow: 1; }

.anim2-icon {
  width: 8%;
  position: relative; }
  .anim2-icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.anim3 {
  position: absolute;
  top: 30%;
  left: 0;
  width: 120%; }
  .anim3-bg {
    margin-left: -40%;
    margin-right: -60%;
    margin-top: -11%;
    width: 200%;
    z-index: 1; }

.anim4-bg {
  margin-left: -30%;
  margin-right: -30%;
  width: 160%;
  z-index: 1; }

.anim4-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -10%;
  width: 25%; }

.anim4-invoice {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 78%; }

.anim4-arrow {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 68%;
  width: 18%; }

.anim5-bg {
  margin-left: -30%;
  margin-right: -30%;
  width: 160%;
  z-index: 1; }

.anim5-btn {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -15%;
  width: 42%; }

.anim5-invoice {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: -10%;
  width: 78%; }

.anim5-arrow {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 58%;
  width: 16.4%; }

.anim6 {
  position: absolute;
  top: 30%;
  left: 0;
  width: 120%; }
  .anim6-bg {
    margin-left: -40%;
    margin-right: -60%;
    margin-top: -11%;
    width: 200%;
    z-index: 1; }

.anim7-bg {
  margin-left: -30%;
  margin-right: -30%;
  width: 160%;
  z-index: 1; }

.anim7-icon {
  position: absolute;
  top: 25%;
  left: 42%;
  width: 44%; }

.anim8-bg {
  margin-left: -50%;
  margin-right: -50%;
  width: 200%;
  z-index: 1; }

.anim8-icon {
  position: absolute;
  top: 22%;
  left: 25%;
  width: 44%; }

.anim9 {
  position: absolute;
  top: 25%;
  left: 0;
  width: 140%; }
  .anim9-bg {
    margin-left: -40%;
    margin-right: -60%;
    margin-top: -11%;
    width: 200%;
    z-index: 1; }
  .anim9-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 73%;
    width: 30%;
    z-index: 1; }

.anim10 {
  position: absolute;
  top: 20%;
  left: 0;
  width: 120%; }
  .anim10-bg {
    margin-left: -40%;
    margin-right: -60%;
    margin-top: -11%;
    width: 200%;
    z-index: 1; }
  .anim10-arrow {
    position: absolute;
    top: 46%;
    left: 33%;
    width: 15%;
    z-index: 2; }

.anim11-bg {
  margin-left: -30%;
  width: 140%;
  z-index: 1; }

.anim11-window {
  position: absolute;
  top: 8%;
  left: 0;
  width: 75%; }

.anim11-btn {
  position: absolute;
  top: 38%;
  left: 18%;
  width: 40%; }

.anim12-bg {
  margin-left: -50%;
  margin-right: -50%;
  width: 200%;
  z-index: 1; }

.anim12-box {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  padding: 5% 8%;
  position: absolute;
  top: 25%;
  left: 10%;
  z-index: 2;
  background: #fff;
  width: 60%;
  overflow: hidden; }

.anim12-title {
  margin-bottom: 2%;
  max-width: 50%; }

.anim12-row {
  display: flex;
  gap: 5%;
  width: 110%;
  align-items: center;
  padding: 4% 10%;
  margin-left: -15%;
  margin-top: -3%; }

.anim12-text1 {
  height: 10px;
  background: #eee;
  width: 10%; }

.anim12-text2 {
  height: 10px;
  background: #eee;
  width: auto;
  flex-grow: 1; }

.anim12--green .anim12-text1,
.anim12--green .anim12-text2 {
  background: #b8e7de; }

.anim12-dolar {
  width: 3%;
  position: relative;
  filter: saturate(0) brightness(1.8); }
  .anim12-dolar img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.anim12-icon {
  width: 5%;
  position: relative; }
  .anim12-icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.fx-anim {
  position: relative; }

.fx-anim__animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
